(function () {
    'use strict';

    angular
        .module('app.business')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business',
                config: {
                    title: 'Business',
                    url: '/business',
                    templateUrl: 'app/business/business.html',
                    settings: {
                        navId: 3,
                        level: 1,
                        order: 3,
                        parentId: 0,
                        content: 'Business',
                        faIcon: 'fa-euro-sign',
                        activatorPermission: [
                            '"OKTOSSZSP"',
                            '"OKTOSSZEDZO"',
                            '"SZAKOKTDIJBEF"',
                            '"OKTOSSZSZAKO"',
                            '"MEGTTAGDOSP"',
                            '"MEGTTAGDOED"',
                            '"SZAKOSPDIJBEF"',
                            '"MEGTTAGDSZO"',
                            '"CSEKKEXPORT"',
                            '"CSV"',
                            '"MEGTNAPIOKTD"',
                            '"MEGTNAPITAGD"',
                            '"MEGTTAGDNYILV"',
                            '"MEGTTAGDBEALL"',
                            '"MEGTHAVIJELLEM"',
                            '"MEGTTAGDHATR"',
                            '"MEGTTAGDNYILV"'
                        ]
                    }
                }
            }
        ];
    }
})();
