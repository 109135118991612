(function () {
    'use strict';

    angular
        .module('app.reports')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports',
                config: {
                    title: 'Reports',
                    url: '/reports',
                    templateUrl: 'app/reports/reports.html',
                    settings: {
                        navId: 4,
                        level: 1,
                        order: 4,
                        parentId: 0,
                        content: 'Reports',
                        faIcon: 'fa-chart-pie',
                        activatorPermission: ['"HIANYZASMEGT"', '"KMEVESJELEDZO"', '"KMEVESJELSZO"', '"KMEVESJELSP"', '"TomAntr"', '"KMEDZOVALTAS"',
                                              '"ESZKNYILVMEGT"', '"AssetManagement"', '"AthleteTransfer"', '"MEGTIDEGENKOLTSTERV"', '"MEGTKPFELM"',
                                              '"DUPLIKALTMEGT"', '"LEJAROMEGT"', '"HEALTHREPORT"', '"VEZETOIHJ"', '"VEZETOIEV"', '"VEZETOIHV"',
                                              '"MEGTHAVIJEL"', '"VEZETOIHE"', '"KMHAVIVERSTERV"', '"KMATFELJATSZ"', '"KMVERSEREDM"', '"MEGTSPFELM"',
                                              '"EDZLATSTAT"', '"MEGTHETIEDZTERVKIMUT"', '"UTAZTERVMEGT"', '"KMSPORTELETKOR"', '"KMSPORTEDZO"',
                                              '"KMSPORTALAK"', '"KMSPORTSZAK"', '"KMSPORTALAKSZO"', '"SPJELENLETINELK"', '"KMBELEPEDZO"',
                                              '"KMBELSZAK"', '"KMKILEPEDZO"', '"KMKILSZAK"']
                    }
                }
            }
        ];
    }
})();
